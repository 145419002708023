.container {
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 100px;
    padding-bottom: 100px;
}

.card {
    width: 85%;
    display: flex;

}

.img-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;

}

.img-container {
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
}

.content {
    padding: 10px 0px 30px 30px;
    width: 100%;
}


.buttons {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;

}

.button {
    white-space: nowrap;
    width: 128px;
    border: 2px solid #005b9e;
    border-radius: 5px;
    color: #005b9e;
    padding: 9px;
    cursor: pointer;
    margin: 0px 40px 15px 0px;
}

.button:hover {
    background-color: #005b9e;
    color: #f2f2f2;
}

@media only screen and (max-width: 750px) {
    .card {
        display: flex;
        flex-direction: column;


    }

    .content {
        padding: 0px 0px 30px 10px;
        width: 100%;

    }

}

@media only screen and (min-width: 1300px) {
    .buttons {
        width: 70%;

    }

}

.p1 {
    color: rgb(58, 57, 57);
    font-size: 1.5em;
    margin-bottom: 40px;
}

.p2 {
    color: rgb(58, 57, 57);
    font-size: 1.3em;
}

.p3 {
    color: rgb(70, 69, 69);
    margin-bottom: 40px;
}