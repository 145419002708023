.topnav {
  background-color: #ff6000;
  border-bottom-right-radius: 30px;
  width: 100%;

}

.logo-burger {
  display: flex;
  justify-content: space-between;

}

.nav a, .nav-right span,
.burger {
  color: #f2f2f2;
  text-align: center;
  padding: 6px 8px;
  text-decoration: none;
  font-size: 15px;
  cursor: pointer;
}

.nav-right span:hover{
  text-decoration: underline;
}

.burger {
  display: none;
  margin-top: 5px;
  font-size: 30px;
}

.topnav a:hover {
  text-decoration: underline;

}

.nav {
  display: flex;
  justify-content: space-around;

}

.nav-left,
.nav-right {
  display: flex;
}

.topnav a img {
  width: 100%;
  height: 50px;
  float: left;
}

.topnav .item {
  margin-top: 20px;
}

.active-desktop {
  width: 0;
  height: 0;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 15px solid white;
}

#item-toggle:checked~div .item {
  display: inline;
}

.logos{
  display: flex;
  align-items: center;
  gap: 1rem;
}

@media only screen and (max-width: 750px) {
  .burger {
    display: inline;
  }

  .topnav .item {
    margin-top: 0px;
    display: none;
  }

  .topnav a img {
    width: 60%;
    height: 30px;
  }

  .nav {
    display: flex;
    flex-direction: column;
  }

  .nav-left,
  .nav-right {
    display: flex;
    flex-direction: column;
  }

  .active-desktop {
    display: none;
  }
  

}