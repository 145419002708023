body {
  margin: 0;
  font-family: "PT Sans","-apple-system,BlinkMacSystemFont","Segoe UI","Roboto","Helvetica Neue",Arial,"Noto Sans",sans-serif,"Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol","Noto Color Emoji";
}

iframe {
  border: none;         
  height: 100vh;        
  width: 100%;
}

::-webkit-scrollbar {
  width: 0px;
}